import React, { useEffect, useRef, useState } from 'react';
import { entitySettingsTabsConfig, useEntitySettingsParams } from '~/pages/settings/config';
import { Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { WaitForLoadedYDoc } from '@property-folders/components/context/YManagerContext';
import { AwarenessData, YDocContentType } from '@property-folders/contract';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { useOfflineAwareness } from '@property-folders/components/hooks/useAwareness';
import * as Y from 'yjs';
import { initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import * as awarenessProtocol from 'y-protocols/awareness';
import { Container, ListGroup } from 'react-bootstrap';
import { FormContext } from '@property-folders/components/context/FormContext';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { entitySettings } from '@property-folders/contract/yjs-schema/model/field';
import clsJn from '@property-folders/common/util/classNameJoin';
import { entitySettingsFieldGroups } from '@property-folders/contract/yjs-schema/model/form';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { ShortId } from '@property-folders/common/util/url';
import { ManageEntitySelector, SelectedEntity } from '~/pages/settings/EntitySettingsEntitySelectPage';
import { SetupEntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { useDevicePreferences } from '@property-folders/components/hooks/useDevicePreferences';
import { MAX_SAFE_INTEGER } from 'lib0/number';
import './EntitySettings.scss';
import { Predicate } from '@property-folders/common/predicate';
import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import { useLocalStorage } from 'react-use';
import { Icon } from '@property-folders/components/dragged-components/Icon';
import { AuthApi } from '@property-folders/common/client-api/auth';

export function EntitySettingsNavMenuPage(props: React.PropsWithChildren) {
  const { entityUuid, settingsGroupTabConfig, settingId, mobile, largeScreen } = useEntitySettingsParams();
  const [navigateToEntity, setNavigateToEntity] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { devicePreferences, setDevicePreferences } = useDevicePreferences();
  const portalableNavRef = useRef<HTMLDivElement>(null);
  const [rememberSetting, setRememberSetting] = useLocalStorage<string>('EntitySettingsSelectedSetting', undefined, { raw: true });
  const [prevState, setPrevState] = useLocalStorage<{url: string, sidebar: any}>('EntitySettingsPrevState', undefined);
  const { data: session } = AuthApi.useGetAgentSessionInfo();

  const onSelectEntity = (entity?: SelectedEntity) => {
    entity?.uuid && setNavigateToEntity(entity.uuid);
  };

  useEffect(() => {
    if (!navigateToEntity) return;
    navigate(`/agency-settings/${ShortId.fromUuid(navigateToEntity)}/${settingsGroupTabConfig?.id ?? ''}`);
  }, [navigateToEntity]);

  useEffect(() => {
    location.state?.prevUrl && setPrevState({
      url: location.state?.prevUrl,
      sidebar: { ...devicePreferences.sidebar }
    });

    setDevicePreferences(s => ({
      ...s,
      sidebar: {
        collapseAt: MAX_SAFE_INTEGER
      }
    }));
  }, []);

  const restorePrevState = () => {
    if (!prevState) return;
    setDevicePreferences(s => ({
      ...s,
      sidebar: {
        ...prevState.sidebar
      }
    }));

    prevState.url && navigate(prevState.url);
  };

  //navigate to last setting visited, or users page if no setting selected
  useEffect(() => {
    if (!entityUuid || !largeScreen) return;
    if (!settingsGroupTabConfig) {
      navigate(`/agency-settings/${ShortId.fromUuid(entityUuid)}/${rememberSetting || 'users'}`);
    } else {
      setRememberSetting(settingsGroupTabConfig.id);
    }
  }, [navigate, settingsGroupTabConfig, entityUuid]);

  const innerContent = <SetupEntitySettingsContext
    settingsGroupTabConfig={settingsGroupTabConfig}
    entityUuid={entityUuid}
    largeScreen={largeScreen}
    afterBreadcrumbs={portalableNavRef.current}
  >
    {entityUuid && <WaitForLoadedYDoc
      yDocId={entityUuid}
      yDocType={YDocContentType.EntitySettings}
      loadingElement={<div></div>}
      children={yDoc => <SetupContexts yDoc={yDoc} entityUuid={entityUuid}>
        {props.children}
      </SetupContexts>}
    />}
  </SetupEntitySettingsContext>;

  if (
    session
    && !session.entities.find(s => s.roles.find(r => r === 'Manager'))
    && !session.isGlobalAdmin
  ) {
    return <Navigate to='/' />;
  }

  return <div id='Entity-Settings' className={'d-flex w-100 h-100 flex-row'}>
    <div className={clsJn(largeScreen || !settingsGroupTabConfig ? 'd-flex' : 'd-none', 'h-100 px-2 py-1 bg-white flex-column')}
      style={{ flex: `0 0 ${mobile ? '100%' : '270px'}`, boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)' }}
    >
      <div className={'px-0'}>
        <div className={'d-flex align-items-center'}>
          <div className={'fs-3 mb-2 ms-1'}>Agency Settings</div>
          {prevState?.url && <div className={'ms-auto'}><Icon name='close' icoClass={'fs-3 mb-1 cursor-pointer'} onClick={restorePrevState}/> </div>}
        </div>
        <ManageEntitySelector selected={entityUuid} onSelect={onSelectEntity} />
      </div>

      {entityUuid && <WaitForLoadedYDoc
        yDocId={entityUuid}
        yDocType={YDocContentType.EntitySettings}
        loadingElement={<div>Loading...</div>}
        children={yDoc => <SetupContexts yDoc={yDoc} entityUuid={entityUuid}>
          <EntitySettingsNavItems />
        </SetupContexts>}
      />}
    </div>

    <div className={'flex-grow-1'}>
      {(!largeScreen && settingsGroupTabConfig)
        ? <ContentTitler
          flex
          scroll
          breadcrumbs={[
            { label: 'Agency Settings', href: entityUuid ? `/agency-settings/${ShortId.fromUuid(entityUuid)}` : undefined },
            settingsGroupTabConfig && { label: settingsGroupTabConfig.title, href: (settingsGroupTabConfig && settingId) ? `/agency-settings/${ShortId.fromUuid(entityUuid)}/${settingsGroupTabConfig.id}` : undefined },
            {}
          ].filter(Predicate.isNotNull)}
          afterBreadcrumbs={<div className={'d-flex align-items-center'}>
            <div ref={portalableNavRef} />
            {location.hash !== '#preview' && prevState?.url && <div className={'ms-auto'}><Icon name='close' icoClass={'fs-3 cursor-pointer'} onClick={restorePrevState}/></div>}
          </div>}
          breadcrumbsClassName={'px-0'}
        >
          {innerContent}
        </ContentTitler>
        :
        <>{innerContent}</>
      }
    </div>
  </div>;
}

function EntitySettingsNavItems(props: React.PropsWithChildren) {
  const { settingsGroupTabConfig, largeScreen, isGa } = useEntitySettingsParams();
  const { value: settings } = useLightweightTransaction<EntitySettingsEntity>({ myPath: '' });
  const enablePf = Boolean(settings?.useNewPropertyTransactions);

  return <Container fluid className='d-flex flex-row h-100 g-0'>
    <ListGroup className={clsJn(largeScreen || !settingsGroupTabConfig ? 'd-flex' : 'd-none', 'flex-column nav mt-2 mx-0 w-100')} style={{ width: largeScreen ? undefined : '100%' }}>
      {entitySettingsTabsConfig.map(tab => (tab.nyi || (!enablePf && tab.pfOnly) || (tab.gaOnly && !isGa)) ? <React.Fragment key={tab.id} /> : (
        <NavLink key={tab.id} to={tab.id} className={clsJn('list-group-item list-group-item-action px-2 border-0', tab.nyi && 'disabled')}>
          {tab.title}
        </NavLink>))}
    </ListGroup>
    <div className='flex-grow-1'>
      {props.children}
    </div>
  </Container>;
}

const formContext: FormContextType = {
  formId: 'entity-settings',
  formName: 'entity-settings',
  wizardTitle: '',
  printTitle: '',
  transactionRules: entitySettings,
  metaRules: { _type: 'Map' },
  fieldGroups: entitySettingsFieldGroups
};

function SetupContexts({
  yDoc,
  entityUuid,
  children
}: React.PropsWithChildren<{ yDoc: Y.Doc, entityUuid: string }>) {
  const awareness = useOfflineAwareness(yDoc) || new awarenessProtocol.Awareness(yDoc);
  return <SetupNetStateContext
    ydoc={yDoc}
    transactionRootKey={EntitySettingsRootKey.Main}
  >
    <SetupNetStateWritingYjsDocContext
      ydoc={yDoc}
      awareness={awareness}
      docName={entityUuid}
      transactionRootKey={EntitySettingsRootKey.Main}
      transactionMetaRootKey={EntitySettingsRootKey.Main}
    >
      <FormContext.Provider value={formContext}>
        <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
          <SetupDebouncedAwarenessContext delay={0}>
            {children}
          </SetupDebouncedAwarenessContext>
        </RoomProvider>
      </FormContext.Provider>
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}
