import React, { useContext, useEffect, useState } from 'react';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { FullPageLoadingSpinner } from '@property-folders/components/dragged-components/FullPageLoadingSpinner';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { AjaxPhp, GetEntityBillingResponse } from '@property-folders/common/util/ajaxPhp';
import {
  canonicalisers,
  friendlyDateFormatter
} from '@property-folders/common/util/formatting';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { Maybe } from '@property-folders/contract';
import { CardDetails } from '@property-folders/components/dragged-components/payment/CardDetails';
import { CardOnRecord } from '@property-folders/components/dragged-components/payment/CardOnRecord';
import { useIsManager } from '@property-folders/components/hooks/useIsManager';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { InvoiceHistory } from '~/pages/settings/tabs/InvoiceHistory';
import { RemoveCardModal } from '@property-folders/components/dragged-components/payment/RemoveCardModal';

export function BillingSettings() {
  const [billingEmail, setBillingEmail] = useState('');
  const { entityPhpInfo } = useContext(EntitySettingsContext);
  const [errors, setErrors] = useState<{ billingEmail: boolean }>({ billingEmail: false });
  const [loading, setLoading] = useState(true);
  const [latest, setLatestData] = useState<Maybe<GetEntityBillingResponse>>(undefined);
  const [titleCardDetails, setTitleCardDetails] = useState<CardDetails | null>(null);
  const [titleCardValid, setTitleCardValid] = useState<boolean>(false);
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const entityId = entityPhpInfo?.entityId;
  const entity = session?.entities.find(e => e.entityId === entityId);
  const isManager = useIsManager({ entity });
  const hasSaSubscription = !!entity?.hasSaSubscription;
  const [subscriptionCardDetails, setSubscriptionCardDetails] = useState<CardDetails | null>(null);
  const [subscriptionCardValid, setSubscriptionCardValid] = useState<boolean>(false);
  const [updatingSubscriptionCard, setUpdatingSubscriptionCard] = useState<number | null>(null);
  const [updatingTitleCardDetails, setUpdatingTitleCardDetails] = useState<boolean>(false);
  const [removeCardModalCardId, setRemoveCardModalCardId] = useState<null | string>(null);
  const [removeCardModalCardDetails, setRemoveCardModalCardDetails] = useState<{ lastFour: string } | null>(null);
  const hasTitlePaymentCard = hasSaSubscription && latest?.titlePayment && latest.titlePayment.cardType;

  const removeCard = async () => {
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) {
      throw new Error('Could not identiy entity');
    }

    if (!removeCardModalCardId) {
      throw new Error('Could not identify card to remove');
    }

    if (removeCardModalCardId.startsWith('subscription_')) {
      const product = Number(removeCardModalCardId.substring(13));
      await AjaxPhp.removeSubscriptionCreditCard({
        entityId,
        productId: product
      });
    } else if (removeCardModalCardId == 'title') {
      await AjaxPhp.removeTitleCreditCard({
        entityId
      });
    } else {
      throw new Error('Could not identify card to remove');
    }

    await reloadPageData();
    setRemoveCardModalCardId(null);
    setRemoveCardModalCardDetails(null);
  };

  const reloadPageData = (abortController?: AbortController) => {
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) {
      return Promise.resolve();
    }

    return AjaxPhp.getEntityBilling({ entityId, signal: abortController?.signal })
      .then(result => {
        if (result === undefined) {
          return;
        }

        setLatestData(structuredClone(result));
      })
      .catch(console.error);
  };

  const onSave = async () => {
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) {
      return;
    }

    await AjaxPhp.updateEntitySetting({
      entityId,
      setting: 'billing-email',
      value: billingEmail as string
    });

    await reloadPageData();
  };

  const onSaveTitleCard = async () => {
    if (titleCardDetails && titleCardValid && titleCardDetails.number && entityId) {
      await AjaxPhp.updateTitlePurchaseBilling({
        card: titleCardDetails,
        entityId
      });
    }

    await reloadPageData();

    setUpdatingTitleCardDetails(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const entityId = entityPhpInfo?.entityId;
    if (!entityId) return;

    reloadPageData(abortController)
      .then(() => setLoading(false));

    return () => {
      abortController.abort();
    };
  }, [entityPhpInfo?.entityId]);

  if (loading || !latest) {
    return <FullPageLoadingSpinner lightMode={true} />;
  }

  const getProductName = (product: string, state?: string) => product.toLowerCase() === 'subscription forms' && state
    ? `${product} - ${state.toUpperCase()}`
    : product;

  const getSubscriptionPriceText = (monthly: boolean, fee: string, trial: boolean, tier: string|number, tierName: string) => trial
    ? 'Trial'
    : `$${fee}/${monthly ? 'month' : 'annum'} (Tier ${tier} - ${tierName})`;

  const getSubscriptionExpiryText = (monthly: boolean, expiryDate: Date) => `${monthly ? 'Renews': 'Expires'} on ${friendlyDateFormatter(expiryDate)}`;

  const saveSubscriptionPayment = async () => {
    if (!updatingSubscriptionCard || !entityPhpInfo?.entityId || !subscriptionCardDetails) {
      return;
    }

    await AjaxPhp.updateSubscriptionPaymentDetails({
      productId: updatingSubscriptionCard,
      card: subscriptionCardDetails,
      entityId: entityPhpInfo.entityId
    });

    await reloadPageData();

    setUpdatingSubscriptionCard(null);
  };

  return <Container fluid={false} className='h-100 position-relative d-flex flex-column flex-start g-0 overflow-auto'>
    <Card>
      <Card.Header>
        <div className='title d-flex w-100 align-items-center justify-content-between'>
          <h3 className='pt-2'>Billing</h3>
          <div className='ms-auto'>
            <AsyncButton disabled={errors.billingEmail} onClick={onSave}>Save</AsyncButton>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Form.Group className='mb-3 col-12 col-md-6'>
          <Form.FloatingLabel label='Billing Email' className='common-label'>
            <Form.Control
              type='text'
              name='billingEmail'
              placeholder=''
              value={latest?.billingEmail}
              onChange={e => {
                setBillingEmail(e.currentTarget.value);
                setLatestData({
                  ...latest,
                  billingEmail: e.currentTarget.value
                });
              }}
              onBlur={() => {
                const { valid, canonical } = canonicalisers.email(billingEmail);
                setBillingEmail(canonical as string);
                setErrors(e => ({ ...e, billingEmail: !valid }));
              }}
              isInvalid={Boolean(errors.billingEmail)}
            />
          </Form.FloatingLabel>
        </Form.Group>
      </Card.Body>
    </Card>

    {hasSaSubscription && <Card className='mt-3'>
      <Card.Header>
        <div className='title d-flex w-100 align-items-center'>
          <h3 className='pt-2'>Title Purchases</h3>
          <div className='ms-auto d-flex' style={{ height: 34 }}>
            {!updatingTitleCardDetails && isManager &&
              <AsyncButton onClick={() => setUpdatingTitleCardDetails(true)}>Update</AsyncButton>}
            {updatingTitleCardDetails &&
              <AsyncButton disabled={!titleCardValid} onClick={onSaveTitleCard}>Save</AsyncButton>}
            {hasTitlePaymentCard && isManager &&
              <AsyncButton
                className='ms-1 btn-danger'
                onClick={() => {
                  setRemoveCardModalCardId('title');
                  setRemoveCardModalCardDetails({ lastFour: latest?.titlePayment.lastFour });
                }}
              >
                Remove Card
              </AsyncButton>
            }
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <p>Users can purchase a variety of products from Land Services SA from directly within documents.
          By default, users will be prompted to enter their payment details each time, when making a purchase.
          Alternatively, you can set up agency-wide payment details here.
        </p>
        {latest.titlePayment && latest.titlePayment.cardType && !updatingTitleCardDetails
          ? <>
            <CardOnRecord
              cardType={latest.titlePayment.cardType}
              name={latest.titlePayment.cardName}
              lastFour={latest.titlePayment.lastFour}
              expiry={`${latest.titlePayment.ccMonth}/${latest.titlePayment.ccYear}`}
            />
          </>
          : !updatingTitleCardDetails || !isManager
            ? <p>No payment method on record</p>
            : <>
              <CardDetails
                setValue={setTitleCardDetails}
                setValid={setTitleCardValid}
              />
            </>
        }
      </Card.Body>
    </Card>}

    <Card className='mt-3'>
      <Card.Header>
        <h3 className='pt-2'>Subscription{latest.subscriptions.length > 1 ? 's' : ''}</h3>
      </Card.Header>
      <Card.Body>
        {latest.subscriptions.map(s => {
          const hasPaymentAlready = s.payment && s.payment.paymentId;
          const expiry = new Date(s.expiry);

          return <>
            <div className='d-flex mt-2'>
              <h4>{getProductName(s.product, s.stateName)}</h4>
              <h4 className='ms-auto'>{getSubscriptionPriceText(s.monthly, s.fee, s.trial, s.tier, s.tierName)}</h4>
            </div>
            <p>{getSubscriptionExpiryText(s.monthly, expiry)}</p>

            <div className='mt-1 d-flex'>
              <h5>Payment</h5>
              {s.monthly && <>
                {s.productId !== updatingSubscriptionCard && isManager && (
                  <Button className='ms-auto' onClick={() => setUpdatingSubscriptionCard(s.productId)}>
                    {hasPaymentAlready ? 'Update' : 'Subscribe'}
                  </Button>
                )}
                {s.productId === updatingSubscriptionCard && (
                  <AsyncButton className='ms-auto' disabled={!subscriptionCardValid}
                    onClick={saveSubscriptionPayment}>Save</AsyncButton>
                )}
                {hasPaymentAlready && isManager &&
                  <AsyncButton
                    className='ms-1 btn-danger'
                    onClick={() => {
                      setRemoveCardModalCardId(`subscription_${s.productId}`);
                      setRemoveCardModalCardDetails({ lastFour: s.payment.lastFour });
                    }}
                  >
                    Remove Card
                  </AsyncButton>
                }
              </>}
            </div>
            {s.monthly
              ? <>
                {s.payment && s.payment.paymentId && updatingSubscriptionCard !== s.productId
                  ? <CardOnRecord
                    cardType={s.payment.cardType}
                    name={s.payment.cardHolder}
                    lastFour={s.payment.lastFour}
                    expiry={`${s.payment.ccMonth}/${s.payment.ccYear}`}
                  />
                  : (updatingSubscriptionCard !== s.productId || !isManager)
                    ? <p>No payment method on record</p>
                    : <CardDetails setValue={setSubscriptionCardDetails} setValid={setSubscriptionCardValid} />}
              </>
              : <p>Invoiced directly from reaforms</p>
            }

            {s.invoiceHistory && s.invoiceHistory.length > 0 && <>
              <h5 className='mt-1'>Subscription History</h5>
              <InvoiceHistory history={s.invoiceHistory} />
            </>}
            <hr />
          </>;
        })}
      </Card.Body>
    </Card>
    {removeCardModalCardId && <RemoveCardModal
      show={!!removeCardModalCardId}
      cardId={removeCardModalCardId}
      onRemove={removeCard}
      onCancel={() => setRemoveCardModalCardId(null)}
      cardDetails={removeCardModalCardDetails}
    />}
  </Container>;
}
