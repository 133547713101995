import { Button, Card, Col, Row } from 'react-bootstrap';

export function RemoteCompletionThankyouPage() {
  return <Row className='d-flex align-items-center justify-content-center g-0 h-100' style={{ backgroundColor: '#2d3748' }}>
    <Col sm={8} md={8} lg={8} xl={6} style={{ maxWidth: '800px', padding: '1rem' }}>
      <Card>
        <Card.Body>
          <div className='d-flex align-items-center justify-content-center h-100'>
            <img src='/legacy/images/reaforms_logo_strap.svg' alt='reaforms' style={{ maxHeight: '60px' }} />
          </div>

          <h4 className='text-center mt-3 mb-3'>Thank you for using reaforms to complete this document.</h4>

          <p>Professional and legally sound documents for real estate agents in Australia. Safely and securely create,
            share
            and sign within minutes. Whether you're in an office, visiting a client, with or without Internet access -
            reaforms provides a professional trusted solution for real estate transactions.</p>

          <div className='text-center'>
            <Button className='border-0' style={{ background: 'var(--clr-reaforms-orange)' }} onClick={() => (window.location.href = '/home/')}>
              Find out more!
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Col>
  </Row>;
}
