import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Lookups } from '@property-folders/common/client-api/lookups';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { Predicate } from '@property-folders/common/predicate';
import { useLocalStorage } from 'react-use';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import { companyTradingAs } from '@property-folders/common/util/formatting';
import { LookupEntitiesResultItem } from '@property-folders/contract';
import { Hint, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { generateDetailRow } from '@property-folders/components/dragged-components/form/NarrowAgentInput';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BelongingEntityMeta, REDUCER_NAME as entityMetaKey } from '@property-folders/common/redux-reducers/entityMeta';
import { groupBy, map, orderBy } from 'lodash';
import clsJn from '@property-folders/common/util/classNameJoin';

export interface SelectedEntity {
  id: number,
  uuid: string
}

interface EntitySelectorProps {
  showSearch?: boolean;
  selected: string | undefined,
  onSelect: (entity?: SelectedEntity) => void
  nameOnlyVersion?: boolean;
}

export function ManageEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const isGlobalAdmin = sessionInfo?.isGlobalAdmin ?? false;

  return isGlobalAdmin
    ? <OnlineEntitySelector {...props} nameOnlyVersion={true} showSearch={true} />
    : <ManagerEntitySelector {...props} nameOnlyVersion={false} showSearch={false} />;
}

function OnlineEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { instance: yManager } = useContext(YManagerContext);
  const [options, setOptions] = useState<EntitySelectorOption[]>([]);
  const [entities, setEntities] = useState(new Map<string, number>());
  const [removeUuids] = useState(new Set<string>());
  const [rememberUuid, setRememberUuid] = useLocalStorage<string>('EntitySettingsSelectedEntityUuid', undefined, { raw: true });

  useEffect(() => {
    const { ac, results } = Lookups.lookupEntities({
      canManage: true
    });

    results
      .then(data => {
        if (!data) return;

        const options = data.items?.map(item => {
          const entity = item as LookupEntitiesResultItem;
          const compositeName = entity.name && entity.tradingName
            ? companyTradingAs(entity.name, entity.tradingName)
            : entity.name || entity.tradingName;
          const label = `${entity.businessName || entity.profileName || compositeName} | ${entity.entityId}`;
          return {
            ...entity,
            label,
            compositeName,
            searchString: `${label} ${compositeName} ${entity.abn} ${entity.rla} ${entity.parentName} ${entity.parentId}`
          };
        })?.filter(Predicate.isNotNull);

        setOptions(options);
        setEntities(new Map(data.items.map(i => [i.entityUuid, i.entityId])));
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, []);

  const onSelect = useCallback((entity?: SelectedEntity) => {
    if (entity && !sessionInfo?.entities.find(e => e.entityUuid === entity.uuid)) {
      removeUuids.add(entity.uuid);
    }
    setRememberUuid(entity?.uuid);
    props.onSelect(entity);
  }, [!!sessionInfo]);

  useEffect(() => {
    if (!yManager) return;

    return () => {
      const uuids = [...removeUuids.values()];
      Promise.allSettled(uuids.map(uuid => yManager.destroyEntry(uuid)))
        .catch(console.error);
    };
  }, [!!yManager]);

  const defaultUuids = useMemo<string[]>(() => {
    const result = new Set<string>();
    if (rememberUuid) result.add(rememberUuid);
    (sessionInfo?.entities || [])
      .map<[number, string]>(e => [e.entityId, e.entityUuid])
      .sort(byMapperFn(([id]) => id))
      .forEach(([_, uuid]) => result.add(uuid));
    return [...result.values()];
  }, [!!sessionInfo]);

  return <EntitySelectorBase
    selected={props.selected}
    onSelect={onSelect}
    options={options}
    entities={entities}
    defaultUuids={defaultUuids}
    nameOnlyVersion={props.nameOnlyVersion}
    showSearch={props.showSearch}
  />;
}

function ManagerEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const reduxEntities = useSelector((state: any) => state?.[entityMetaKey] as BelongingEntityMeta | undefined);

  const { entities, options } = useMemo<{
    entities: Map<string, number>,
    options: EntitySelectorOption[]
  }>(() => {
    if (!sessionInfo?.entities[0] || !reduxEntities?.[sessionInfo.entities[0]?.entityId]) return {
      entities: {},
      options: []
    };

    const manageableEntities = (sessionInfo?.entities || [])
      .filter(entity => entity.roles.includes('Manager') || entity.roles.includes('Admin'))
      .map(entity => ({
        id: entity.entityId,
        entityUuid: entity.entityUuid,
        ...reduxEntities?.[entity.entityId]
      }));

    const entities = new Map<string, number>();
    for (const entity of manageableEntities) {
      entities.set(entity.entityUuid, entity.id);
    }

    return {
      entities,
      options: manageableEntities.map(entity => {
        const compositeName = entity.name && entity.tradeName
          ? companyTradingAs(entity.name, entity.tradeName)
          : entity.name || entity.tradeName;
        const label = entity.profileName || compositeName || '';

        return {
          ...entity,
          label,
          compositeName,
          searchString: `${label} ${compositeName} ${entity.abn} ${entity.rla}`,
          value: entity
        };
      })
    };
  }, [sessionInfo?.entities, reduxEntities]);

  useEffect(() => {
    if (options.length === 1) {
      const selected = options[0];
      props.onSelect({ id: selected.entityId, uuid: selected.entityUuid });
    }
  }, [options]);

  if (options.length === 1) {
    const selected = options[0];

    return <div className='form-control p-0 ps-2'>
      <div className='border-0 p-0 pt-1 pe-4 m-0 fw-bold '>
        {selected.label}
      </div>
      {selected?.profileName && <div className='extra-row d-flex pe-3 pb-0 '>{selected.name}</div>}
      {selected &&
        <div className='extra-row d-flex pe-3 pb-2 '>{generateDetailRow(selected as EntitySelectorOption)}</div>}
    </div>;
  }

  return <EntitySelectorBase
    selected={props.selected}
    onSelect={props.onSelect}
    options={options}
    entities={entities}
    showSearch={props.showSearch}
    nameOnlyVersion={props.nameOnlyVersion}
  />;
}

type EntitySelectorOption = LookupEntitiesResultItem & {
  label: string;
  compositeName: string;
  nameOnlyVersion?: boolean;
};

function EntitySelectorBase(props: EntitySelectorProps & {
  options: EntitySelectorOption[],
  entities: Map<string, number>,
  defaultUuids?: string[]
}) {
  const options = props.options || [];
  const entities = props.entities;
  const useSearch = props.showSearch !== false;
  const [selected, setSelected] = useState<EntitySelectorOption | null>(null);
  const [filter, setFilter] = useState('');

  const selectOption = (option: EntitySelectorOption[] | undefined | null) => {
    if (option?.length) {
      const sel = option[0];
      setSelected(sel);
      const id = sel ? entities.get(sel.entityUuid) : undefined;
      props.onSelect(sel && id ? { id, uuid: sel.entityUuid } : undefined);
    } else {
      setSelected(null);
      props.onSelect(undefined);
    }
  };

  useEffect(() => {
    if (props.selected) {
      const match = options.find(o => o.entityUuid === props.selected);
      if (match) {
        setSelected(match);
        return;
      }
    }

    if (props.defaultUuids?.length) {
      for (const defaultUuid of props.defaultUuids) {
        const match = options.find(o => o.entityUuid === defaultUuid);
        if (match) {
          selectOption([match]);
          return;
        }
      }
    }

    selectOption(options);
  }, [props.selected, entities, options]);

  const ref = useRef(null);

  return <Typeahead
    id={'select-entity'}
    labelKey='label'
    placeholder='Select an agency'
    options={options}
    filterBy={option => {
      const filterTerms = filter.split(' ').map(f => f.trim());
      const optionTerm = option.searchString?.toLowerCase();

      // for each search term, check it exists in the option somewhere
      return !filterTerms.find(ft => {
        return optionTerm?.indexOf(ft.toLowerCase()) === -1;
      });
    }}
    renderMenu={(results, { renderMenuItemChildren, newSelectionPrefix, paginationText, ...menuProps }) => {
      const grouped = groupBy(results, 'parentName');
      let idx = 0;

      return <Menu {...menuProps} style={{
        overflow: 'hidden',
        padding: 0,
        top: 44,
        filter: 'drop-shadow(0px 0px 3px darkgrey)',
        minWidth: '100%'
      }} key={idx}>
        {useSearch && <div className={'m-1 position-absolute w-100 pe-2'}>
          <Form.Control
            value={filter}
            placeholder={'Search for Agency'}
            autoFocus={true}
            onChange={e => setFilter(e.target.value)}
            onMouseDown={e => e.stopPropagation()}
            onKeyDown={k => {
              const comp = ref?.current;
              if (!comp) return;

              if (['Enter'].includes(k.key) && !comp.state.activeItem) {
                if (comp.state.initialItem.entityUuid) {
                  selectOption([comp.state.initialItem]);
                  comp.blur();
                  return;
                }
              }

              if (['ArrowDown', 'ArrowUp', 'Enter'].includes(k.key)) {
                comp?._handleKeyDown(k);
              }
            }}
            className="no-box-shadow w-100"
          />
        </div>}
        <div style={{
          marginTop: useSearch ? '40px' : undefined,
          maxHeight: '70vh',
          overflowY: 'auto',
          overflowX: 'hidden'
        }}>
          {
            map(grouped, group => {
              const hasParent = group?.length > 1 && group?.[0]?.parentName;
              return <div className={clsJn(hasParent && 'entity-parent-group')} key={idx}>
                {hasParent && <div className={'group-label'}>{group?.[0]?.parentName}</div>}
                {orderBy(group, e => e.parentId)?.map((result) => {
                  const entity = result as EntitySelectorOption;
                  const { label, compositeName } = entity;
                  return <MenuItem key={entity.entityUuid} option={{ label, compositeName, ...entity }} position={idx++}
                    className={'bordered-menu-item pe-4'}>
                    <div className="d-flex flex-column">
                      <div className="fw-bold">{label}</div>
                      {entity.profileName && <div>{compositeName}</div>}
                      {props.nameOnlyVersion
                        ? <div className='d-flex flex-grow-1 justify-content-between combo-field-subtext'>
                          <div>Trading Name: {entity.tradingName || '(none)'}</div>
                          <div>Company Name: {entity.companyName || '(none)'}</div>
                        </div>
                        : generateDetailRow(entity as EntitySelectorOption)
                      }
                    </div>
                  </MenuItem>;
                })}
              </div>;
            })
          }
          {!results?.length && <div className={'p-2'}>{menuProps.emptyLabel}</div>}
        </div>
      </Menu>;
    }}
    onChange={selectOption}
    selected={selected ? [selected] : []}
    highlightOnlyResult={true}
    ref={ref}
    emptyLabel='No matching agencies found.'
    renderInput={({ inputRef, referenceElementRef, value, ...inputProps }, props) => {
      return (
        <Hint key={inputProps.id}>
          <div className='form-control p-0 ps-2'>
            <div
              className='border-0 p-0 pt-1 pe-4 m-0 fw-bold fake-input-select form-select cursor-pointer'
              onClick={e => {
                inputProps?.onClick?.(e);
              }}>
              {value}
            </div>
            {selected?.profileName && <div onClick={e => inputProps?.onClick?.(e)}
              className='extra-row d-flex pe-3 pb-0 cursor-pointer'>{selected.name}</div>}
            {selected && <div onClick={e => inputProps?.onClick?.(e)}
              className='extra-row d-flex pe-3 pb-2 cursor-pointer'>{generateDetailRow(selected as EntitySelectorOption)}</div>}
          </div>
        </Hint>
      );
    }}
  />;
}
