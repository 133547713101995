import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { buildYDoc } from '@property-folders/components/form-gen-util/buildYDoc';
import {
  insertAllImmerPath,
  useLightweightTransaction,
  useTransactionField
} from '@property-folders/components/hooks/useTransactionField';
import { propertyFolder } from '@property-folders/contract/yjs-schema/model/field';
import { v4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { depthObservation } from '@property-folders/components/form-gen-util/yjsObserver';
import { fullRevalidation } from '@property-folders/common/redux-reducers/validation';
import { findIndex } from 'lodash';
import { RoomProvider } from '@y-presence/react';
import { initialPresence } from './TransactionHomePage';
import {
  AwarenessData,
  FormCodeUnion,
  MaterialisedPropertyData,
  TransactionMetaData
} from '@property-folders/contract';
import * as Y from 'yjs';
import * as awarenessProtocol from 'y-protocols/awareness';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { toOfflineProperty, UNCREATED_DOCS_LIST_KEY, YManager } from '@property-folders/common/offline/yManager';
import { AddressTitlePair } from '@property-folders/components/dragged-components/form/AddressTitlePair';
import { useOfflineAwareness } from '@property-folders/components/hooks/useAwareness';
import { LandType } from '@property-folders/common/types/Address';
import { GuidanceNoteType } from '@property-folders/common/types/GuidanceNoteType';
import { Maybe } from '@property-folders/common/types/Utility';
import { newTransaction, ntFieldGroups } from '@property-folders/contract/yjs-schema/model/form';
import { PrimaryAgentInput } from '@property-folders/components/dragged-components/form/PrimaryAgentInput';
import { useForm } from '@property-folders/components/hooks/useForm';
import {
  generateHeadlineFromMaterialisedData, materialiseProperty,
  summariseAddressesOrTitles
} from '@property-folders/common/yjs-schema/property';
import { PropertyRootKey } from '@property-folders/contract/yjs-schema/property';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { VendorWizardStepPage } from '@property-folders/components/dragged-components/Wizard/VendorWizardStepPage';
import '@property-folders/components/dragged-components/Form.scss';
import '@property-folders/components/dragged-components/Wizard/Wizard.scss';
import { composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { requirePropertyDetailsMessage } from '@property-folders/common/util/formatting/constants';
import { WizardDisplayContext, WizardDisplayContextType } from '@property-folders/components/context/WizardContexts';
import { clsJn as classNames } from '@property-folders/common/util/classNameJoin';
import { applyMigrationsV2_1 } from '@property-folders/common/yjs-schema';
import {
  PrefillFolderFieldType,
  residentialPrefill
} from '@property-folders/contract/yjs-schema/model/prefill/residential';
import { getPathParentAndIndex, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { DocumentFieldType, PathSegments } from '@property-folders/contract/yjs-schema/model';
import './TransactionNew.scss';
import {
  ShowGuidanceNotesButton
} from '@property-folders/components/dragged-components/guidance/ShowGuidanceNotesButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormContext } from '@property-folders/components/context/FormContext';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { checkBuildPath } from '@property-folders/common/util/build-skeleton';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { FileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { SpinnerButton } from '@property-folders/components/dragged-components/AsyncButton';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { AddPropertyApology } from '@property-folders/components/display/errors/page';
import { OfflineProperties } from '@property-folders/common/offline/offlineProperties';

const formName = 'newTrans';
const formRules = newTransaction;
const transactionRules = propertyFolder;

const NEW_AS_FORM_CONTEXT = { formName, formRules, transactionRules, fieldGroups: ntFieldGroups };

type TransactionNewInnerProps = {
  onCreate?: (headline: string)=>void,
  closeNotNewDialog?: ()=>void,
  onUserClickedCreate?: ()=>void,
  displayNewGuidance?: boolean,
  inModal?: boolean
};

export const TransactionNewInner = ({ onCreate, closeNotNewDialog, onUserClickedCreate, displayNewGuidance, inModal = false }: TransactionNewInnerProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { instance: fileSync } = useContext(FileSyncContext);
  const { docName, transactionRootKey, formName, ydoc } = useForm();
  const focusErrList = useSelector((state: any) => state?.validation?.focusErrList?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName]);
  const [userShouldSeeAllValidation, setUserShouldSeeAllValidation] = useState(false);

  const wizardDisplayContextState = useMemo<WizardDisplayContextType>(()=>({
    showFocusErrors: userShouldSeeAllValidation,
    focusErrList
  }), [userShouldSeeAllValidation, focusErrList]);
  const allowCreate = useSelector((state: any) => {
    const errorPathTree = state?.validation?.errorPaths?.[docName ?? '']?.[transactionRootKey ?? '']?.[formName];
    return !!errorPathTree && !Object.keys(errorPathTree).length;
  });
  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ myPath: '' });
  const { value: landType, handleUpdate: updateLandType } = useTransactionField<Maybe<LandType>>({ myPath: 'landType' });

  if (!landType) {
    updateLandType(LandType.Residential, true, undefined, undefined, 'Residential');
  }
  const headline = generateHeadlineFromMaterialisedData(transRoot);
  const summarisedHeadline = summariseAddressesOrTitles(transRoot);

  const [processing, setProcessing] = useState(false);
  const createClick = async () => {
    if (!docName) return;
    if (!fileSync) return;
    setProcessing(true);
    try {
      onUserClickedCreate?.();
      setUserShouldSeeAllValidation(true);
      if (allowCreate) {
        const newFormCode = searchParams.get('createForm');
        const propertySlug = LinkBuilder.seoFriendlySlug(docName, headline);
        if (newFormCode) {
          const result = await handleNewForm(ydoc, newFormCode as FormCodeUnion, fileSync);
          if (!result) {
            console.log('failed to create document, just navigate to the created property folder');
            onCreate && onCreate(`/properties/${propertySlug}`);
            return;
          }
          const typeSlug = FormTypes[newFormCode].subscription ? 'subscription' : 'document';
          const documentSlug = LinkBuilder.seoFriendlySlug(result.formId);
          onCreate && onCreate(`/properties/${propertySlug}/${typeSlug}/${documentSlug}`);
        } else {
          onCreate && onCreate(`/properties/${propertySlug}`);
        }
        return;
      }
      // Not allowed path

      for (const classTarget of focusErrList) {
        const focusTargetElement = document.querySelector(`.${classTarget}`);
        if (focusTargetElement) {
          focusTargetElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
          break;
        }
      }
    } finally {
      setProcessing(false);
    }
  };
  const errorFocusProperty = composeErrorPathClassName([],'property-section');
  const listingDiv = <div className={'w-100'} style={!inModal ? { maxWidth: '800px', marginTop: '1rem' } : undefined}>
    {displayNewGuidance && <WizardStepPage
      name='newGuide'
      label='New Property Folder'
      icon='information'
      embedded={inModal}
    >
      <div className='lead mb-3'>
          Property Folders help you create and store related, compliant forms for a specific property transaction, in the one place.
        <ShowGuidanceNotesButton noteId='propertyFoldersBlurb' />
      </div>
    </WizardStepPage>}
    <WizardStepPage
      name="property"
      label="Property"
      icon='home'
      generalFocusClass={errorFocusProperty}
      sectionErrorText={requirePropertyDetailsMessage+', or specify a headline to identify this folder'}
      embedded={inModal}
    >
      <div className='LandType-cell'>
        <WrField.Select
          name="landType"
          label="Land Type"
          options={[
            { name: `${LandType.Residential}`, label: 'Residential' },
            { name: `${LandType.Rural}`, label: 'Rural', disabled: true },
            { name: `${LandType.Commercial}`, label: 'Commercial', disabled: true }
          ]}
          valueType='int'
          myPath='landType'
          canClear={false}
          guidanceNoteId={GuidanceNoteType.ResidentialLandType}
        />
      </div>

      <Row className={'mt-4'}>
        <AddressTitlePair autoAddFirst={!closeNotNewDialog} minimalUi={true} />
      </Row>

      <Row className={'mt-4'}>
        <h4>Headline</h4>
        <p>When we display this property to reaforms users, vendors, and purchasers, by default this property will be referred to by its address. You can customise how this is displayed here.</p>
        <div><WrField.Control name='headline' myPath='headline' label="Headline" valuePlaceholder={summarisedHeadline}></WrField.Control></div>
      </Row>
    </WizardStepPage>

    <VendorWizardStepPage autoAddFirst={true} name="vendor" label="Vendor" icon="person" embedded={inModal} />

    <WizardStepPage name="agent" label="Agent" icon='real_estate_agent' embedded={inModal}>
      <PrimaryAgentInput/>
    </WizardStepPage>

    {!inModal && <div className={'d-flex flex-row mx-0 mx-sm-2 mx-md-4 mb-4 pe-2'}>
      {closeNotNewDialog
        ? <Button onClick={closeNotNewDialog} variant='primary' className='ms-auto'>Done</Button>
        : <>
          <Button onClick={() => navigate('/properties')} variant='secondary' className='ms-auto'>Cancel</Button>
          {<span className='d-inline-block'>
            <SpinnerButton
              disabled={!allowCreate}
              processing={processing}
              onClick={createClick}
              variant='primary'
              className='ms-2'
              title={allowCreate ? undefined : 'A new transaction cannot be created without either an Address, a Title or a Headline'}
            >Create</SpinnerButton>
          </span>}
        </>
      }
    </div>}
  </div>;
  return <WizardDisplayContext.Provider value={wizardDisplayContextState}>
    <div className={classNames({
      'WizardPanel overflow-auto': true,
      'w-100 h-100 d-flex flex-column align-items-center': !inModal
    })} >
      {listingDiv}
    </div>
  </WizardDisplayContext.Provider>;

};

function prefill(
  treeHere: any,
  prefillDefnHere: PrefillFolderFieldType | undefined,
  fieldDefnHere: DocumentFieldType | undefined,
  treeRoot: any,
  fieldDefnRoot: DocumentFieldType,
  currentPath: PathSegments = []
): boolean {
  let anyUpdate = false;
  if (!fieldDefnHere || !prefillDefnHere) {
    return false;
  }

  if (prefillDefnHere?._prefill && treeHere == null) {
    if (fieldDefnHere._type === 'Array' && prefillDefnHere._prefill.entries) {
      checkBuildPath(normalisePathToStr(currentPath),fieldDefnRoot, treeRoot);
      insertAllImmerPath(treeRoot, prefillDefnHere._prefill.entries, normalisePathToStr(currentPath), fieldDefnRoot);
      anyUpdate = true;
    } else if (prefillDefnHere._prefill.value != null) {
      checkBuildPath(normalisePathToStr(currentPath),fieldDefnRoot, treeRoot);
      const { indexer,parent } = getPathParentAndIndex(currentPath, treeRoot, true);
      parent[indexer] = prefillDefnHere._prefill.value;
      anyUpdate = true;
    }
  }

  if (fieldDefnHere._type === 'Map') {
    for (const childKey in prefillDefnHere) {
      if (childKey.startsWith('_')) {
        continue;
      }

      anyUpdate = prefill(treeHere?.[childKey], prefillDefnHere[childKey], fieldDefnHere[childKey], treeRoot, fieldDefnRoot, [...currentPath, childKey]) || anyUpdate;

    }
  }
  if (fieldDefnHere._type === 'Array' && prefillDefnHere._children && Array.isArray(treeHere)) {
    for (const childNodeIdx in treeHere) {
      const childNode = treeHere[childNodeIdx];
      const nodeKey = `[${childNode?.id ?? childNodeIdx}]`;
      anyUpdate = prefill(childNode, prefillDefnHere._children, fieldDefnHere._children, treeRoot, fieldDefnRoot, [...currentPath, nodeKey]) || anyUpdate;
    }
  }

  return anyUpdate;
}

export const TransactionNewSetup = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionStateRaw = AuthApi.useLoggingOnSessionInfo();
  const [localInitialised, setLocalInitialised] = useState(false);
  const transactionRootKey = PropertyRootKey.Data; // New transactions don't yet have alt keys, so intended
  const { ydoc, localProvider, docName: ydocId } = useMemo(()=>{
    const newUUID = v4();
    const uncreatedDocList = JSON.parse(localStorage.getItem(UNCREATED_DOCS_LIST_KEY) ?? '[]') as Array<string>;
    uncreatedDocList.push(newUUID);
    localStorage.setItem(UNCREATED_DOCS_LIST_KEY, JSON.stringify(uncreatedDocList));
    const result = buildYDoc(newUUID, true);
    YManager.instance().markUserViewing(newUUID, true);
    return result;
  }, []);
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  // This ref is used because we want a current value for the cleanup, not a previous value.
  // So we are basically making a static store of data that is not versioned. It will also not
  // trigger rerenders, but at the moment we aren't using that render for anything.
  const confirmRef = useRef(false);
  const awareness = useOfflineAwareness(ydoc);

  useEffect(()=>{
    if (!sessionInfo) return;
    if (!ydoc) return;
    if (!localProvider) return;
    localProvider.whenSynced.then(() => {
      setLocalInitialised(true);
      const now = new Date();
      let didPrefill = false;
      applyMigrationsV2_1<TransactionMetaData>({
        doc: ydoc,
        docKey: PropertyRootKey.Meta.toString(),
        typeName: 'Property',
        migrations: [{
          name: 'initialise metadata',
          fn: state => {
            state.createdUtc = now.toISOString();
          }
        },{
          name: 'set property id',
          docKey: PropertyRootKey.Data.toString(),
          fn: (state: any) => {
            (state as MaterialisedPropertyData).id = ydocId;
          }
        },{
          name: 'run prefill on new property',
          docKey: PropertyRootKey.Data.toString(),
          fn: (state: any) => {
            didPrefill = prefill(state as MaterialisedPropertyData, residentialPrefill, propertyFolder, state, propertyFolder);
            return didPrefill;
          }
        },{
          name: 'mark prefilled',
          fn: state => {
            state.prefilled = didPrefill;
          }
        }]
      });
    });
  }, [localProvider, ydoc, !!sessionInfo]);

  useEffect(()=>{
    const existingKnownIDsList = JSON.parse(localStorage.getItem(UNCREATED_DOCS_LIST_KEY) ?? '[]') as Array<string>;
    new Promise<void>(resolve => {
      const promises = existingKnownIDsList.map(async id => {
        if (id === ydocId) return; // Don't destroy the item we just made
        try {
          await YManager.instance().destroyEntry(id);
        } catch {
          console.warn(`failed to cleanup id ${id}`);
        }
      });
      Promise.allSettled(promises).then(() => resolve());
    });

    return () => {
      if (!confirmRef.current) {
        YManager.instance().destroyEntry(ydocId);
      }
    };
  }, []);

  const handleCreate = (navigateLocation: string) => {
    const agentId = sessionStateRaw?.data?.session?.agentId;
    if (!agentId) return; // 0 is an invalid agent ID
    const materialised = materialiseProperty(ydoc);
    if (!(ydocId && materialised && materialised.data.id)) {
      console.error('Invalid property');
      return;
    }
    const uncreatedDocList = JSON.parse(localStorage.getItem(UNCREATED_DOCS_LIST_KEY) ?? '[]') as Array<string>;
    const removalIndex = findIndex(uncreatedDocList, e => e === ydocId);
    if (removalIndex >= 0) {
      uncreatedDocList.splice(removalIndex,1);
    } else {
      console.warn('splice key not found');
    }

    OfflineProperties.write(ydocId, agentId, toOfflineProperty(ydocId, agentId, materialised)).then(() => {
      confirmRef.current = true;
      navigate(navigateLocation);
    });
  };

  useEffect(()=>{
    if (ydoc && ydocId && transactionRootKey && localInitialised) {
      const changeFunction = (evts: Y.YEvent<any>[])=>depthObservation(evts, dispatch, ydocId, transactionRootKey, formName, transactionRules, formRules);
      const currentDocMap = ydoc.getMap(transactionRootKey);
      dispatch(fullRevalidation({
        docName: ydocId,
        mapRoot: transactionRootKey,
        formName: NEW_AS_FORM_CONTEXT.formName,
        dataTree: currentDocMap.toJSON(),
        transactionFieldsDefn: transactionRules,
        formRules,
        context: { formCode: formName }
      }));
      currentDocMap.observeDeep(changeFunction);
      return ()=>{currentDocMap.unobserveDeep(changeFunction);};
    }
  }, [ydoc, ydocId, transactionRootKey, localInitialised]);

  const staticProviderValue = useMemo(()=>({
    ydoc,
    awareness,
    docName: ydocId,
    transactionRootKey,
    transactionMetaRootKey: PropertyRootKey.Meta,// As above,
    clearDeclareDebounce: () => undefined,
    declareDebounce: () => undefined
  }), [ydoc, ydocId, transactionRootKey]);
  return localInitialised
    ? <YjsDocContext.Provider value={staticProviderValue}>
      <FormContext.Provider value={NEW_AS_FORM_CONTEXT}>
        <RoomProvider<AwarenessData> awareness={awareness ?? new awarenessProtocol.Awareness(ydoc)} initialPresence={initialPresence}>
          <TransactionNewInner {...props} onCreate={handleCreate} displayNewGuidance={true}/>
        </RoomProvider>
      </FormContext.Provider>
    </YjsDocContext.Provider>
    : <div>Getting ready</div>;
};

export function TransactionNew(props: any) {
  return <ErrorBoundary FallbackComponent={AddPropertyApology}>
    <TransactionNewSetup {...props} />
  </ErrorBoundary>;
}
