import { useEffect, useRef, useState } from 'react';
import './PhpPage.scss';
import { NavigateFunction, useLoaderData, useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import React from 'react';
import { PDFPreviewer } from '@property-folders/components/dragged-components/PDFViewer/PDFPreviewer';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { useGesture } from '@use-gesture/react';
import { useBreakpointValue } from '@property-folders/components/hooks/useBreakpointValue';

interface PhpPageProps {
  url: string;
  needsReload?: boolean;
}

interface LoaderData {
  additionalQueryParams?: { [key: string]: string }
}

type MessageEventData =
  | undefined
  | null
  | string;

const PREVIEW_PSEUDO_TARGET = '#newformpdfpreview';

export function PhpPage({ url: urlBase, needsReload }: PhpPageProps) {
  const navigate = useNavigate();
  const navType = useNavigationType();
  const location = useLocation();
  const { hash: locationHash, pathname, search } = location;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const loaderData: LoaderData | null = useLoaderData() as LoaderData;
  const [previewUrl, internalSetPreviewUrl] = useState<string | undefined>(undefined);
  const [previewFileName, setPreviewFileName] = useState<string | undefined>(undefined);
  const renderTextLayer = useBreakpointValue({ base: false, sm: true }, true);

  const setPreviewUrl =(newUrl: string | undefined) =>{
    if (newUrl) {
      navigate(pathname+search+PREVIEW_PSEUDO_TARGET, { preventScrollReset: true });
    }

    internalSetPreviewUrl(newUrl);
  };

  const url = new URL(urlBase);
  LinkBuilder.applyQueryString(url.searchParams, {
    ...loaderData?.additionalQueryParams,
    wrapped: true
  });

  useEffect(() => {
    if (!iframeRef.current?.contentWindow) {
      return;
    }
    const messageHandler = (e: MessageEvent<MessageEventData>) => {
      basePhpPageMessageHandler({
        e,
        navigate,
        setPreviewUrl,
        needsReload,
        setPreviewFileName
      });
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [iframeRef, location.key]);

  useGesture({
    onDrag: ({ swipe: swipeXY }) => {
      const swipeX = swipeXY[0];
      if (previewUrl && swipeX > 0) {
        navigate(-1);
      }
    }
  },
  {
    target: window,
    drag: { swipe: { velocity: 0.25, duration: 500 } }
  });

  useEffect(()=>{
    if (previewUrl && locationHash !== PREVIEW_PSEUDO_TARGET && navType === 'POP') {
      setPreviewUrl(undefined);
      return;
    }
  }, [locationHash]);
  useEffect(()=>{
    if (locationHash === PREVIEW_PSEUDO_TARGET && previewUrl == undefined) {
      navigate(pathname+search, { replace: true, preventScrollReset: true }); // This will retrigger this effect, but having removed the hashes, nothing should happen
    }
  }, [previewUrl]);
  return <>
    <iframe
      key={pathname+search+(locationHash === PREVIEW_PSEUDO_TARGET ? '' : locationHash)} // if this value is changed, it will force the iframe to recreate
      // unfortunately it seems as if using the #preview target also reloads this, so the page is
      // recreated when a preview is opened, and then closed again.
      style={{ border: 'none', width: '100%' }}
      ref={iframeRef}
      src={url.toString()}
    />
    {!!previewUrl && <PDFPreviewer
      url={previewUrl}
      filename={previewFileName}
      onClose={() => navigate(-1)}
      tryMakeScrollWork={true}
      renderTextLayer={renderTextLayer}
    />}
  </>;
}

interface ReactNavigationOpts {
  reloadSession?: boolean;
}

export function basePhpPageMessageHandler({
  e,
  navigate,
  setPreviewUrl,
  setPreviewFileName,
  needsReload
}: {
  e: MessageEvent<MessageEventData>,
  navigate: NavigateFunction,
  setPreviewUrl: (url: string | undefined) => void,
  setPreviewFileName: (fileName: string | undefined) => void,
  needsReload?: boolean
}) {
  if (!e.data) {
    return;
  }

  switch (e.data) {
    case 'redirect_to_home':
      window.location.href = '/index.php';
      break;

    case 'redirect_to_marketing':
      window.location.href = '/home/';
      break;

    case 'redirect_to_login':
      window.location.href = '/login';
      break;

    case 'reload':
      window.location.reload();
      break;

    default: {
      if (typeof e.data !== 'string') {
        return;
      }

      try {
        const d: {action: string, path: string, filename?: string, opts?: ReactNavigationOpts} = JSON.parse(e.data);
        switch (d.action) {
          case 'react_navigation':
            if (d.path.startsWith('/legacy/tools') || d.path.startsWith('/tools') || needsReload || d.opts?.reloadSession) {
              window.location.href = d.path;
            } else {
              navigate(d.path);
            }

            return;
          case 'react_preview_pdf':
            window.focus();
            setPreviewUrl(d.path);
            setPreviewFileName(d.filename);
            return;
          default:
            console.warn(`unexpected action: ${d.action} for path ${d.path}`);
            return;
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
}
