import React, { useCallback, useEffect, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { YManager } from '@property-folders/common/offline/yManager';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { cleanupOtherSessions } from '@property-folders/common/util/user-cleanup-common';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { uncheckedLogout } from '@property-folders/common/util/logout-common';
import { FileSyncContext, SetupFileSyncContext } from '@property-folders/components/context/fileSyncContext';
import { SetupYManagerContext } from '@property-folders/components/context/YManagerContext';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';
import { AgentInfoState } from '~/App';
import { UnauthenticatedRoutedApp } from '~/UnauthenticatedRoutedApp';
import { RoutedApp } from '~/RoutedApp';
import { alwaysBypassRoutes } from '~/alwaysBypassRoutes';
import { EntityYDocObserver } from './EntityYDocObserver';

const unauthenticatedRoutes = [
  ...alwaysBypassRoutes.filter(r => r.endsWith('.php')),
  'remote-completion/thank-you',
  'frontend/index.php' // just for local use
];

export function ValidSessionApp({ needsReload }: {needsReload: boolean;}) {
  const [loggingOut, setLoggingOut] = useState(false);
  const [agentInfo, setAgentInfo] = useState<AgentInfoState>({
    agentId: 0,
    agentUuid: '',
    offlineProperties: false,
    propertyFoldersEnabled: false,
    enableWebSockets: false
  });
  const [authenticated, setAuthenticated] = useState<'unknown' | 'unauthenticated' | 'authenticated' | 'unauthenticated-redirect'>('unknown');
  const [forceTelemetryCollection, setForceTelemetryCollection] = useState(false);

  // Rather than relying on an effect to decide this, which is a side effect-y process, just
  // straight up cut out any possibility of seeing authenticated on an unauthenticated route.
  // These pages are now treated as no signing pages even when login exists. If we want a signin
  // optional page, we would probably need a new route list and treat it seperately.
  const url = new URL(window.location.href);
  const operateUnauthenticated = unauthenticatedRoutes.findIndex(r => url.pathname.startsWith(`/${r}`)) !== -1;
  const maskedAuthenticatedState = operateUnauthenticated ? 'unauthenticated' : authenticated;

  const sessionStateRaw = AuthApi.useLoggingOnSessionInfo();
  const {
    status: sessionState,
    data
  } = operateUnauthenticated ? { status: undefined, data: undefined } : sessionStateRaw;

  useEffect(() => {

    if (sessionState === 'error') {
      setAuthenticated('unauthenticated');

      const url = new URL(window.location.href);

      if (unauthenticatedRoutes.findIndex(r => url.pathname.startsWith(`/${r}`)) !== -1) {
        // it's fine, carry on and return the unauthenticated router provider
        return;
      }

      if (window.location.pathname === '/' || window.location.pathname === '/index.php') {
        window.location.href = LinkBuilder.reaformsFromRoot('/frontend/index.php');
      } else {
        const redirectUrl = `${window.location.pathname ?? '/'}${window.location.search ?? ''}`;

        if (!window.location.href.includes('/login')) {
          setAuthenticated('unauthenticated-redirect');
          window.location.href = LinkBuilder.loginPage(redirectUrl).toString();
        }
      }
    }

    if (sessionState === 'success') {
      if (!data.session || !('agentId' in data.session)) {
        return;
      }

      setAuthenticated('authenticated');
      let offline = data?.session?.offlineProperties;
      if (offline == null) {
        offline = true;
      }
      const propertyFoldersEnabled = !!data?.session?.featureFlags?.newPropertyFolders;
      const isManager = data.session.entities.some(e => e.roles.some(r => r === 'Manager'));
      const isGlobalAdmin = !!data?.session?.isGlobalAdmin;

      setAgentInfo({
        agentId: data?.session?.agentId || 0,
        agentUuid: data?.session?.agentUuid || '',
        offlineProperties: offline,
        propertyFoldersEnabled,
        enableWebSockets: propertyFoldersEnabled || isManager || isGlobalAdmin
      });
      setForceTelemetryCollection(!!data?.session.forceTelemetryCollection);
    }
  }, [sessionState, data]);

  useEffect(() => {
    if (!agentInfo.agentId) {
      return;
    }
    cleanupOtherSessions().catch(console.error);
  }, [agentInfo.agentId]);

  // periodically request a session extension from the php site.
  // this keeps our session cookie active a bit longer
  useEffect(() => {
    const duration = 60000 * 60; // 60 minutes
    let timeout: NodeJS.Timeout | string | number | undefined;
    const timerHandler = async () => {
      try {
        await AjaxPhp.extendSession();
      } catch (err: unknown) {
        console.error(err);
      } finally {
        timeout = setTimeout(timerHandler, duration);
      }
    };
    timerHandler();
    return () => clearTimeout(timeout);
  }, []);

  const wrappedLogout = async (agentId: number, agentUuid: string | undefined) => {
    if (await YManager.hasPendingOutboundMessages(agentId)) {
      if (!window.confirm('You are currently offline. Logging off will lose unsaved changes. Are you sure you want to log off?')) {
        return;
      }
    }
    setLoggingOut(true);
    YManager.unbind();
    await uncheckedLogout(agentId, agentUuid);
  };
  const logoutClickHandler = useCallback(() => {
    if (!agentInfo.agentId) {
      return;
    }
    wrappedLogout(agentInfo.agentId, agentInfo.agentUuid).catch(console.error);
  }, [agentInfo.agentId]);

  if (loggingOut) {
    return <div>Logging Out...</div>;
  }

  // should be offlineEnabled={agentInfo.offlineProperties}
  switch (maskedAuthenticatedState) {
    case 'authenticated':
      return <SetupFileSyncContext agentId={agentInfo.agentId}>
        <FileSyncContext.Consumer>
          {value =>
            <SetupYManagerContext
              user={{ agentId: agentInfo.agentId, agentUuid: agentInfo.agentUuid }}
              offlineEnabled={true}
              fileSync={value.instance}
              enabled={agentInfo.enableWebSockets}
              impersonator={data?.session?.type === 'agent' && !!data.session.impersonator}
            >
              <EntityYDocObserver
                operateUnauthenticated={operateUnauthenticated}
                agentId={agentInfo.agentId}
                propertyFoldersEnabled={agentInfo.propertyFoldersEnabled}
              />
              <RoutedApp
                onLogoutClick={logoutClickHandler}
                sessionState={sessionState}
                agentInfo={agentInfo}
                needsReload={needsReload}
                forceTelemetryCollection={forceTelemetryCollection}
              ></RoutedApp>
            </SetupYManagerContext>
          }
        </FileSyncContext.Consumer>
      </SetupFileSyncContext>;

    case 'unauthenticated':
      return  <UnauthenticatedRoutedApp routes={unauthenticatedRoutes}/>;

    case 'unauthenticated-redirect':
      return <FullPageLoading textMessage='Redirecting to login page...' />;

    case 'unknown':
    default:
      return <FullPageLoading />;
  }
}
