import 'allotment/dist/style.css';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import React, { useContext, useEffect, useState } from 'react';
import { AllotmentPaneMode, useContentPreviewAllotmentStateEntitySettings } from '@property-folders/components/hooks/useContentPreviewAllotmentState';
import { Allotment, LayoutPriority } from 'allotment';
import clsJn from '@property-folders/common/util/classNameJoin';
import { Button, Container } from 'react-bootstrap';
import { EmailSettingsEdit, EmailSettingsPreview } from '~/pages/settings/tabs/EmailSettings';
import { ReaformsBrandingSettingsEdit, ReaformsBrandingSettingsPreview } from '~/pages/settings/tabs/ReaformsBrandingSettings';
import { GreatformsBrandingSettingsEdit, GreatformsBrandingSettingsPreview } from '~/pages/settings/tabs/GreatformsBrandingSettings';
import { PortalSettingsEdit, PortalSettingsPreview } from '~/pages/settings/tabs/PortalSettings';
import { PortalSelfRegistrationSettingsEdit, PortalSelfRegistrationSettingsPreview } from '~/pages/settings/tabs/PortalSelfRegistrationSettings';
import { RulesSettingsEdit } from '~/pages/settings/tabs/RulesSettings';
import { DisclosureOfBenefitsEdit } from '~/pages/settings/tabs/DisclosureOfBenefits';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { ShortId } from '@property-folders/common/util/url';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MarketingPreview, MarketingTemplatesEdit } from '~/pages/settings/tabs/MarketingTemplates';
import { UserManagementEdit } from '~/pages/settings/tabs/UserManagement';
import { GlobalAdminOnly } from '~/pages/settings/tabs/GlobalAdminOnly';
import { AgencyDetails } from '~/pages/settings/tabs/AgencyDetails';
import { createPortal } from 'react-dom';
import { BillingSettings } from '~/pages/settings/tabs/BillingSettings';

export function EntitySettingsEditPage() {
  const {
    onVisibleChange,
    showContentPane,
    showPreviewPane,
    allotmentPaneMode,
    setAllotmentPaneMode,
    splitEnabled
  } = useContentPreviewAllotmentStateEntitySettings();
  const { settingId } = useParams();
  const { afterBreadcrumbs, settingsGroupTabConfig, entityUuid, entityPhpInfo } = useContext(EntitySettingsContext);
  const [previewKey, setPreviewKey] = useState('');
  const previewEnabled = Boolean(settingsGroupTabConfig?.preview) && (!settingsGroupTabConfig?.preview?.childrenOnly || settingId);
  const { value: settings } = useLightweightTransaction<EntitySettingsEntity>({ myPath: '' });
  const enablePf = Boolean(settings?.useNewPropertyTransactions);
  const navigate = useNavigate();
  const location = useLocation();
  const hashPreview = '#preview';

  //close preview on nav if its fullscreen
  useEffect(() => {
    if (allotmentPaneMode === AllotmentPaneMode.Preview && location.hash !== hashPreview) onPreviewClose();
  }, [location]);

  // redirect to users page if invalid setting, or pf only setting and not pf entity
  useEffect(() => {
    if (!entityUuid) {
      navigate('/agency-settings/');
      return;
    }
    if (!settingsGroupTabConfig || (settingsGroupTabConfig?.pfOnly && !enablePf)) {
      navigate(`/agency-settings/${ShortId.fromUuid(entityUuid)}/users`);
    }
  }, [settingsGroupTabConfig?.pfOnly, enablePf]);

  useEffect(() => {
    entityPhpInfo?.reload();
  }, [enablePf]);

  const onPreviewKey = (key: string, show?: boolean) => {
    setPreviewKey(key);
    if (!previewEnabled) return;
    if (!key) return;
    if (show) {
      splitEnabled ? setAllotmentPaneMode(AllotmentPaneMode.Both) : setAllotmentPaneMode(AllotmentPaneMode.Preview);
      navigate(hashPreview);
    }
  };

  const onPreviewClose = () => {
    setPreviewKey('');
    setAllotmentPaneMode(AllotmentPaneMode.Content);
    location.hash === hashPreview && navigate('#');
  };

  if (!settingsGroupTabConfig) return <></>;

  const contentPane = <>{showContentPane && <div className='position-relative h-100 w-100 px-0 px-xl-0 WizardPanel'>
    {(() => {
      switch (settingsGroupTabConfig.id) {
        case 'billing':
          return <BillingSettings />;
        case 'email':
          return <EmailSettingsEdit onPreviewKey={onPreviewKey}/>;
        case 'reaforms':
          return <ReaformsBrandingSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'greatforms':
          return <GreatformsBrandingSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'portal':
          return <PortalSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'portal-self-registration':
          return <PortalSelfRegistrationSettingsEdit onPreviewKey={onPreviewKey} />;
        case 'rules':
          return <RulesSettingsEdit />;
        case 'disclosure-of-benefits':
          return <DisclosureOfBenefitsEdit />;
        case 'users':
          return <UserManagementEdit />;
        case 'marketing':
          return <MarketingTemplatesEdit onPreviewKey={onPreviewKey} />;
        case 'ga':
          return <GlobalAdminOnly key={entityPhpInfo?.entityId ?? ''} />;
        case 'details':
          return <AgencyDetails key={entityPhpInfo?.entityId ?? ''} />;
        default:
          return <Container>Not yet implemented</Container>;
      }
    })()}
  </div>}</>;
  const previewPane = <>{showPreviewPane && previewEnabled &&
    <div className='position-relative h-100 w-100' style={{ backgroundColor: settingsGroupTabConfig.preview?.bg || 'var(--clr-reaforms-grey)' }}>
      {(() => {
        switch (settingsGroupTabConfig.id) {
          case 'email':
            return <EmailSettingsPreview previewKey={previewKey} />;
          case 'reaforms':
            return <ReaformsBrandingSettingsPreview previewKey={previewKey} />;
          case 'greatforms':
            return <GreatformsBrandingSettingsPreview previewKey={previewKey} />;
          case 'portal':
            return <PortalSettingsPreview previewKey={previewKey} />;
          case 'portal-self-registration':
            return <PortalSelfRegistrationSettingsPreview previewKey={previewKey} />;
          case 'marketing':
            return <MarketingPreview previewKey={previewKey} onPreviewClose={onPreviewClose} />;
          default:
            return <></>;
        }
      })()}
    </div>}</>;

  // breadcrumbs will manage back navigation probably.
  return <div className='w-100 h-100 d-flex flex-column'>
    {afterBreadcrumbs && createPortal(<>
      {allotmentPaneMode === AllotmentPaneMode.Preview &&
        <Button variant='link' title='Close' size='sm' className='px-0 py-0 border-0 bg-transparent link-secondary'
          onClick={() => splitEnabled
            ? setAllotmentPaneMode(AllotmentPaneMode.Both)
            : onPreviewClose()}>Back</Button>}
    </>, afterBreadcrumbs)}
    <div className={clsJn('alot-container position-relative h-100 w-100 d-flex')}>
      <Allotment snap={splitEnabled} onVisibleChange={onVisibleChange}>
        <Allotment.Pane minSize={splitEnabled ? 400 : undefined} preferredSize={splitEnabled ? 760 : undefined} priority={LayoutPriority.High} visible={showContentPane}>
          {contentPane}
        </Allotment.Pane>
        {previewEnabled && <Allotment.Pane minSize={splitEnabled ? 200 : undefined} visible={splitEnabled ? showPreviewPane : !showContentPane}>
          {previewPane}
        </Allotment.Pane>}
      </Allotment>
    </div>
  </div>;
}
