import React, { useContext, useMemo, useState } from 'react';
import { Wizard } from '@property-folders/components/dragged-components/Wizard/Wizard';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FormContextSetup,
  getDefaultReportMissing
} from '@property-folders/components/form-gen-util/yjsStore';
import { fieldGroups as contractFieldGroups, saleContractFormRules } from '@property-folders/contract/yjs-schema/model/form/sale-contract-residential';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { ExtraFormCode, FormCode, FormInstance, MaterialisedPropertyData, SigningSessionFieldType, TransactionMetaData } from '@property-folders/contract';
import { useUpdateInstanceModifiedOnLoad } from '@property-folders/components/hooks/useUpdateInstanceModifiedOnLoad';
import '../FormInput.scss';
import { SaleContractPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import { useForm } from '@property-folders/components/hooks/useForm';
import { ContractPrice } from '@property-folders/components/dragged-components/form/SaleContract/contractPrice';
import { ContractSchedule } from '@property-folders/components/dragged-components/form/SaleContract/ContractSchedule';
import { ContractSpecialConditionsFinance } from '@property-folders/components/dragged-components/form/SaleContract/ContractSpecialConditionsFinance';
import { ContractSpecialConditionsPurchaserSale } from '@property-folders/components/dragged-components/form/SaleContract/ContractSpecialConditionsPurchaserSale';
import { useYdocBinder } from '@property-folders/components/hooks/useYdocBinder';
import { ContractGst } from '@property-folders/components/dragged-components/form/SaleContract/contractGst';
import { generateHeadlineFromMaterialisedData } from '@property-folders/common/yjs-schema/property';
import { VendorWizardStepPage } from '@property-folders/components/dragged-components/Wizard/VendorWizardStepPage';
import { PurchaserWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PurchaserWizardStepPage';
import { ContractSettlementWizardStepPage } from '@property-folders/components/dragged-components/Wizard/ContractSettlementWizardStepPage';
import { PropertyWizardStepPage } from '@property-folders/components/dragged-components/Wizard/PropertyWizardStepPage';
import { AnnexuresSection } from '@property-folders/components/dragged-components/form/AnnexuresSection';
import { SaleContractVariationPdfDefinitionProvider } from '@property-folders/common/util/pdfgen/definitions/helpers';
import { propertyFolder, propertyMeta } from '@property-folders/contract/yjs-schema/model/field';
import { ClausesWizardStepPage } from '@property-folders/components/dragged-components/Wizard/ClausesWizardStepPage';
import { requirePropertyDetailsMessage } from '@property-folders/common/util/formatting/constants';
import { canonicalisers, composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { SigningConfigurationInterceptor } from '@property-folders/components/dragged-components/signing/SigningConfiguration';

import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { PropertyBackgroundJobsContext } from '@property-folders/components/context/PropertyBackgroundJobs';
import { TransactionFormProps } from '@property-folders/common/types/TransactionFormProps';
import { IPdfDefinitionProvider } from '@property-folders/common/types/PDFDefinition';
import { useMatches } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { UpdatesPendingBadge } from '@property-folders/components/display/properties/UpdatesPendingBadge';
import { FormUtil } from '@property-folders/common/util/form';
import { OfferActionModal, OfferActionModalType } from '~/pages/OfferManagementPage';
import { MaterialisedPropertyMetaRecord } from '@property-folders/services/lib/dal/ddb/property-transaction/db-types';
import { PurchaserApi } from '@property-folders/common/client-api/purchaserApi';
import { v4 } from 'uuid';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { FallbackModal } from '@property-folders/components/display/errors/modals';
import { CrumbDefn } from '@property-folders/common/types/BreadCrumbTypes';
import { PathSegments } from '@property-folders/contract/yjs-schema/model';
import { AssociatedAgentsInput } from '@property-folders/components/dragged-components/form/AssociatedAgentsInput';
import { useSetAuctionSettlement } from '@property-folders/components/hooks/useSetAuctionSettlement';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { LineageContext } from '@property-folders/components/hooks/useVariation';

const FORM_CODE = FormCode.RSC_ContractOfSale;
const FORM_LABEL = FormTypes[FORM_CODE].label;
const FORM_RULES = saleContractFormRules;
const TRANSACTION_RULES = propertyFolder;
const FORM_CONTEXT: Omit<FormContextType, 'formId'> = {
  formName: FORM_CODE,
  printTitle: FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  wizardTitle: FormTypes[FORM_CODE].wizardTitle ?? FormTypes[FORM_CODE].printTitle ?? FormTypes[FORM_CODE].label,
  formRules: FORM_RULES,
  transactionRules: TRANSACTION_RULES,
  metaRules: propertyMeta,
  fieldGroups: contractFieldGroups,
  reportMissing: getDefaultReportMissing(FORM_CODE, saleContractFormRules)
};

const varWatch: {[sectionKey:string]: PathSegments[]} = {
  assocAgent: [
    ['authAssoc'],
    ['assocAgents']
  ],
  property: [
    ['saleAddrs'],
    ['saleTitles'],
    ['titleDivision']
  ],
  vendor: [['vendors'],['primaryVendor']],
  purchaser: [
    ['purchasers'],
    ['primaryPurchaser']
  ],
  contractGst: [
    ['vendorGst'],
    ['contractPrice', 'inputTaxed'],
    ['contractPrice', 'gstIncluded'],
    ['contractPrice', 'marginApplied']
  ],
  contractPrice: [
    ['contractPrice', 'purchasePrice'],
    ['contractPrice', 'purchaseGst'],
    ['contractPrice', 'deposit'],
    ['contractPrice', 'depositPayAt'],
    ['contractPrice', 'depositDateOther']
  ],
  contractSettlement: [['contractSettlement']],
  contractSchedule: [
    ['chattels'],
    ['exclusions'],
    ['saaTenant', 'tenantEnable'],
    ['encroach'],
    ['encroachEnable'],
    ['notiWorks'],
    ['notiWorksEnable'],
    ['vendorWorks'],
    ['vendorWorksEnable'],
    ['cgtEnable'],
    ['gstWithholdEnable'],
    ['pool'],
    ['contractSchedule', 'noForm1NoCoolMatters']
  ],
  contractSpecialGeneral: [
    ['contractSpecial', 'hideSection']
  ],
  contractSpecialFinance: [
    ['contractSpecial', 'financeRequired'],
    ['contractSpecial', 'financeDeadline'],
    ['contractSpecial', 'financeDeadlineTime'],
    ['contractSpecial', 'financeAmount'],
    ['contractSpecial', 'financeInterestRate'],
    ['contractSpecial', 'financeProperties']
  ],
  contractSpecialPurchaserSale: [
    ['contractSpecial', 'purchaserSaleRequired'],
    ['contractSpecial', 'purchaserSaleContractDeadline'],
    ['contractSpecial', 'purchaserSaleSettlementDeadline'],
    ['contractSpecial', 'saleSettleAlsoRequired'],
    ['contractSpecial', 'salePropertySingleLine'],
    ['contractSpecial', 'purchaserSaleMinimumPrice']
  ],
  contractOther: [['contractOther']]
};

export const SalesContractForm = (props: TransactionFormProps) => {
  // this code is similar to what's on the RSAA, so...
  // future: maybe lift signing stuff out into separate pages within TransactionHomePage
  // and also create a usePdfDefinitionProvider hook to automatically generate the right thing based on YjsDocContext and FormContext
  // and maybe a useInitiateSigningCallback hook to build the right kind of signing initiation flow.
  // anyway, no time to do that now!
  const formCode = FORM_CODE;
  const formId = props.formId;
  const { ydoc, docName: propertyId, transactionMetaRootKey, transactionRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { binder: dataBinder } = useImmerYjs<MaterialisedPropertyData>(ydoc, transactionRootKey);
  const updatedBreadcrumb = useMemo(()=>[...(props.breadcrumbs ?? []), { label: FORM_LABEL }], [props.breadcrumbs]);
  const pdfDefinition = useMemo<IPdfDefinitionProvider>(() => {
    return props.variationsMode
      ? new SaleContractVariationPdfDefinitionProvider(dataBinder,
        metaBinder,
        ExtraFormCode.SCV_ContractOfSaleVariation,
        formId,
        true)
      : new SaleContractPdfDefinitionProvider(dataBinder,
        metaBinder,
        formCode,
        formId,
        true);
  }, [dataBinder, metaBinder, formCode, formId, props.variationsMode]);

  return <FormContextSetup formId={formId} base={FORM_CONTEXT}>
    <SigningConfigurationInterceptor
      {...props}
      breadcrumbs={updatedBreadcrumb}
      pdfDefinition={pdfDefinition}
      documentRenderer={() => (<SetupPdfLoadStateContext>
        <SalesContractFormInner
          {...props}
          breadcrumbs={updatedBreadcrumb}
          pdfDefinition={pdfDefinition}/>
      </SetupPdfLoadStateContext>)}
    />
  </FormContextSetup>;
};

export const SalesContractFormInner = ({ formId, parentPath = '', myPath = '', breadcrumbs, entityLogoLoadedUri, pdfDefinition }: TransactionFormProps & {pdfDefinition: IPdfDefinitionProvider}): JSX.Element => {
  const { ydoc, docName, transactionRootKey, transactionMetaRootKey, formName: formCode, formFamilyCode, wizardTitle } = useForm();
  const { updateDraft } = useYdocBinder<MaterialisedPropertyData>({ path: '' });
  const { bindState, binder: metaBinder } = useImmerYjs<TransactionMetaData>(ydoc, transactionMetaRootKey);
  const { data: meta } = bindState<MaterialisedPropertyMetaRecord>(state => state);
  const { data: formInstances, update: updateMeta } = bindState<FormInstance[]>(state => state?.formStates?.[FORM_CODE]?.instances || []);
  const backgroundJobsContext = useContext(PropertyBackgroundJobsContext);
  const instance = useMemo(()=>{
    return formInstances && formInstances?.find(inst => inst.id === formId);
  }, [formInstances, formId]);
  const isSubmitted = FormUtil.instanceIsSubmitted(instance);
  const { variationsMode, snapshotData } = useContext(LineageContext);
  const hideSpecialConditions = variationsMode && !snapshotData?.contractSpecial?.hideSection;
  const [offerActionModal, setOfferActionModal] = useState<{ visible: boolean, type?: OfferActionModalType, offerId?: string }>({ visible: false });

  // when the form instance is loaded, then update modified date, but only if it's not completed.
  // I suppose if we wanted to go more crazy we could do it if the user actually changes a field
  useUpdateInstanceModifiedOnLoad(instance, formId, FORM_CODE, updateMeta);

  const { value: transRoot } = useLightweightTransaction<MaterialisedPropertyData>({ parentPath, myPath });
  const headline = generateHeadlineFromMaterialisedData(transRoot);

  const handleOfferAction = (email: { subject: string, message: string } | undefined) => {
    setOfferActionModal(p =>({ ...p, visible: false }));
    if (!offerActionModal.offerId) return;

    switch (offerActionModal.type) {
      case OfferActionModalType.decline:
        PurchaserApi.declineOffer(transRoot?.id, offerActionModal.offerId, email);
        break;

      case OfferActionModalType.withdraw:
        PurchaserApi.withdrawOffer(transRoot?.id, offerActionModal.offerId, email);
        break;
    }
  };

  useSetAuctionSettlement(transRoot, updateDraft);

  const { showAssociated, lockedFields, showAuthorisation, forceOpenAssociated } = useMemo(()=>{
    if (transRoot?.authAssoc === false) return { showAssociated: false, lockedFields: [] };
    if (transRoot?.authAssoc == null) return { showAssociated: true, lockedFields: [], showAuthorisation: true };
    const lockedFields: string[] = [];
    const agent = transRoot?.assocAgents?.[0];
    if (agent == null) {
      return { showAssociated: true, lockedFields };
    }
    const sp = agent.salesp?.[0];
    const noSpData = sp == null || !sp.name;
    const abnInvalid = !!agent.abn && !canonicalisers.abnacn(agent.abn).valid;
    if (!noSpData) lockedFields.push('salesName');
    if (agent.company) lockedFields.push('agencyName');
    if (agent.rla) lockedFields.push('agencyRla');
    if (agent.abn && !abnInvalid) lockedFields.push('agencyAbn');
    return { showAssociated: true, lockedFields, forceOpenAssociated: abnInvalid };

    // We only watch for the flag change. We don't want this section to disappear as soon as data is
    // filled out. What this does still mean though, is that on a page reload, this section will
    // vanish, as the data will have been filled then. Otherwise we have to check the main data,
    // while also checking the current data. We also don't really know if the information has
    // changed as a contract can be created before an RSAA has been signed, and also if an RSAA is
    // varied but not yet signed. So we'll just go off what is stored in the contract.

  }, [!!transRoot?.id]);

  const clauseUUID = metaBinder?.get()?.formStates?.[formFamilyCode]?.clauseChildId || '0';
  const label = metaBinder?.get()?.formStates?.[formFamilyCode]?.label;

  const currentPathing = useMatches();
  const contractManagementHref = currentPathing[currentPathing.length-2].pathname + '/contracts';
  const updatedBread: CrumbDefn[] = [...breadcrumbs.slice(0,-1), { label: 'Contract Management', href: contractManagementHref }, breadcrumbs[breadcrumbs.length-1]];

  const previousPortalDetails = instance?.signing?.session?.contractFromPortal;
  const onVoidSigning = previousPortalDetails ? ()=>{
    metaBinder?.update(draft=>{
      const instance = draft?.formStates?.[FormCode.RSC_ContractOfSale]?.instances?.find(inst => inst.id === formId);
      const session = instance?.signing?.session;
      const partySourceTypeAllowList = previousPortalDetails.partySourceTypeRestriction;
      if (!session || !partySourceTypeAllowList || partySourceTypeAllowList.length === 0) return;

      for (const party of instance.signing?.parties??[]) {
        if (partySourceTypeAllowList.includes(party.source.type)) continue;
        for (const field of session.fields) {
          if (!(field.type === SigningSessionFieldType.Signature
          && field.partyId === party.id)) continue;
          delete field.file;
          delete field.isWetSigned;
          delete field.smsSecret;
          delete field.text;
          delete field.timestamp;
        }
        FormUtil.clearSigningPartyResponse(party);

      }

      session.id = v4();
      session.partySourceTypeRestriction = partySourceTypeAllowList;
      delete session.contractFromPortal;
    });
  }:undefined;

  const hideSCs = transRoot?.contractSpecial?.hideSection;

  return (
    <>
      <Wizard
        formId={formId}
        formName={formCode}
        transactionRootKey={transactionRootKey}
        transactionMetaRootKey={transactionMetaRootKey}
        docName={docName}
        title={wizardTitle}
        subTitle={label}
        afterTitle={<div className={'d-flex'}>
          <UpdatesPendingBadge propertyId={backgroundJobsContext?.propertyId} headline={headline} show={backgroundJobsContext.envelopeChangesAvailable} className='ms-auto me-2 mt-1' />
          {isSubmitted && <>
            <Button variant={'outline-secondary'} className={'me-2'} onClick={() => setOfferActionModal({ visible: true, type: OfferActionModalType.withdraw, offerId: transactionRootKey })}>Withdraw</Button>
            <Button variant={'outline-secondary'} onClick={() => setOfferActionModal({ visible: true, type: OfferActionModalType.decline, offerId: transactionRootKey })}>Decline</Button>
          </>}
        </div>}
        printHeadline={headline}
        breadcrumbs={updatedBread}
        pdfDefinition={pdfDefinition}
        ydoc={ydoc}
        signing={true}
        entityLogoLoadedUri={entityLogoLoadedUri}
        onVoidSigning={onVoidSigning}
      >

        { showAssociated ? <WizardStepPage
          key='agent-insertion'
          name="agent"
          label={'Associated Agents'}
          icon="real_estate_agent"
          variationWatchPaths={varWatch.assocAgent}
          independentCollapsible={!forceOpenAssociated}
        >
          <AssociatedAgentsInput noReauth={!showAuthorisation} lockedFields={lockedFields} />
        </WizardStepPage> : null}

        <PropertyWizardStepPage
          name="property"
          key="property"
          label="Property"
          icon="home"
          variationWatchPaths={varWatch.property}
          generalFocusClass={composeErrorPathClassName([],'property-section')}
          sectionErrorText={requirePropertyDetailsMessage}
        />

        <VendorWizardStepPage
          name="vendor"
          key="vendor"
          label="Vendor"
          icon="person"
          variationWatchPaths={varWatch.vendor}
        />

        <PurchaserWizardStepPage
          name="purchaser"
          key="purchaser"
          label="Purchaser"
          icon="shopping_cart"
          variationWatchPaths={varWatch.purchaser}/>

        <WizardStepPage
          name='contractGst'
          key='contractGst'
          label="GST Conditions"
          icon="percent"
          variationWatchPaths={varWatch.contractGst}
        >
          <ContractGst myPath='contractPrice' />
        </WizardStepPage>

        <WizardStepPage
          name='contractPrice'
          key='contractPrice'
          label="Price"
          icon="attach_money"
          variationWatchPaths={varWatch.contractPrice}
        >
          <ContractPrice myPath='contractPrice' />
        </WizardStepPage>

        <ContractSettlementWizardStepPage
          name='contractSettlement'
          key='contractSettlement'
          label="Settlement"
          icon="schedule"
          variationWatchPaths={varWatch.contractSettlement}
        />

        <WizardStepPage
          name='contractSchedule'
          key='contractSchedule'
          label="Particulars"
          pageOnlyLabel='Property and sale particulars'
          icon="rule"
          variationWatchPaths={varWatch.contractSchedule}
        >
          <ContractSchedule />
        </WizardStepPage>

        {hideSpecialConditions
          ? null
          : <WizardStepPage
            name="specialCond"
            key="specialCond"
            label="Special Conditions"
            icon="flowsheet"
            variationWatchPaths={varWatch.contractSpecialGeneral}
          >

            {/*
          Note: This section should probably only appear if the hide was previously selected.

        So the plan here is to have a single checkbox to hide the entire special
        conditions section, while strikethrough will remain on each individual
        condition. It will also explain that in the case of a template contract,
        the options for the prospective purchasers will be hidden, as they currently
        already are if the vendor selects that they won't consider them. Further,
        depending on what is currently implemented in the case of auctions, this section
        might also be hidden in the case of auctions if the contract already renders without them.
        if they instead appear struck through, then this section should persist.
        Implementing the block of prospective purchasers could either be done with
        a group function, or by finding the places where we already consume the disallow
        flags and just add this as an or.
        */}
            <WrField.BoolCheck name={'hideSCs'} label="Do not show Special Conditions in document"
              parentPath="contractSpecial" myPath="hideSection"/>
          </WizardStepPage>}

        <WizardStepPage
          name='contractSpecialFinance'
          key='contractSpecialFinance'
          label='Purchaser finance'
          icon="account_balance"
          pdfFocus='subsection-purchaser-finance'
          variationWatchPaths={varWatch.contractSpecialFinance}
        >
          {hideSCs
            ? <div>The Special conditions have been hidden</div>
            :<ContractSpecialConditionsFinance />
          }
        </WizardStepPage>

        <WizardStepPage
          name='contractSpecialPurchaserSale'
          key='contractSpecialPurchaserSale'
          label='Purchaser sale'
          icon="other_houses"
          pdfFocus='subsection-purchaser-sale'
          variationWatchPaths={varWatch.contractSpecialPurchaserSale}
        >
          {hideSCs
            ? <div>The Special conditions have been hidden</div>
            :<ContractSpecialConditionsPurchaserSale />
          }
        </WizardStepPage>

        <ClausesWizardStepPage
          myPath={`clausesByFamily.[${clauseUUID}].clauses`}
          name='clauses'
          key='clauses'
          label='Additional Conditions'
          icon='playlist_add'
          variationWatchPaths={useMemo(()=>[['clausesByFamily', `[${clauseUUID}]`, 'clauses']], [clauseUUID])}
        />

        <WizardStepPage
          name="annexures"
          key="annexures"
          label="Annexures"
          icon='attach_file'
          variationDeterminationCallback={[()=>(instance?.annexures?.length??0) > 0]}
        >
          <AnnexuresSection formCode={formCode} formId={formId} ydoc={ydoc} transactionMetaRootKey={transactionMetaRootKey}/>
        </WizardStepPage>

      </Wizard>
      <ErrorBoundary fallbackRender={fallback=><FallbackModal {...fallback} show={!!offerActionModal.visible} onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}/>}>
        <OfferActionModal
          visible={offerActionModal.visible}
          onClose={() => setOfferActionModal(p =>({ ...p, visible: false }))}
          onSubmit={handleOfferAction}
          actionType={offerActionModal.type}
          headline={headline}
          propertyMeta={meta}
        />
      </ErrorBoundary>
    </>
  );
};
