import {
  HumanTimestampText,
  SupportedRelativeTimeFormatUnit
} from '@property-folders/components/dragged-components/HumanTimestamp';
import { Button } from 'react-bootstrap';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import React, { useState } from 'react';
import { GetEntityBillingResponse } from '@property-folders/common/util/ajaxPhp';

type History = GetEntityBillingResponse['subscriptions'][0]['invoiceHistory'];

type Props = {
  history: History;
};

export function InvoiceHistory({ history }: Props) {
  const [itemsToShow, setItemsToShow] = useState(10);

  const allItems = history.sort((a, b) => b.dateStamp.localeCompare(a.dateStamp));
  const items = allItems.slice(0, itemsToShow);

  return <>
    <table className='table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {items.map(h => <>
          <tr className='align-middle'>
            <td>
              <HumanTimestampText
                timestamp={new Date(h.dateStamp)}
                maxInterval={SupportedRelativeTimeFormatUnit.day}
              />
            </td>
            <td>{h.description}</td>
            <td>{h.action === 2 && (
              <Button onClick={() => {
                window.open(LinkBuilder.reaformsFromRoot(`/tools/taxinvoice.php?ID=${h.invoiceId}`));
              }}>
              Download
              </Button>
            )}</td>
          </tr>
        </>)}
        {allItems.length > itemsToShow &&
        <tr>
          <td>
            <span className='link' onClick={() => setItemsToShow(allItems.length)}>Show all items</span>
          </td>
          <td></td>
          <td></td>
        </tr>
        }
      </tbody>
    </table>
  </>;

}
